body, #root, html {
  margin: 0;
  font-family: 'Metropolis-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}


@font-face {
  font-family: 'Metropolis-Black';
  src: local('Metropolis-Black'), url(./styles/fontFiles/metropolis/Metropolis-Black.otf) format('OpenType');
}

@font-face {
  font-family: 'Metropolis-ExtraBold';
  src: local('Metropolis-ExtraBold'), url(./styles/fontFiles/metropolis/Metropolis-ExtraBold.otf) format('OpenType');
}

@font-face {
  font-family: 'Metropolis-Regular';
  src: local('Metropolis-Regular'), url(./styles/fontFiles/metropolis/Metropolis-Regular.otf) format('OpenType');
}